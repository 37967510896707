import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import {PageUrl} from './Constants';
import Application from './config/application';

const CancelRefund = () => {
  return (
  <div id="main-wrapper">
   <Helmet title={composePageTitle('Cancellation & Refund Policy')} />
   <Header />
   <section className="page-header page-header-dark bg-secondary">
    <div className="container">
     <div className="row align-items-center">
      <div className="col-md-8">
       <h1>Cancellation & Refund Policy</h1>
      </div>
      <div className="col-md-4">
       <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
        <li>
         <RouterLink to={PageUrl.HOME}>Home</RouterLink>
        </li>
        <li className="active">Cancellation & Refund Policy</li>
       </ul>
      </div>
     </div>
    </div>
   </section>
   <div id="content">
    <div className="container">
     <div className="bg-white shadow-md rounded p-4">
  
      <p>At {Application.ORG_DISPLAY_NAME}, we comprehend that travel plans can unexpectedly change. Our commitment revolves around offering you transparent and comprehensive guidelines concerning cancellations and refunds. We urge you to meticulously review the subsequent policy prior to confirming any bookings.</p>
      <p><strong>Booking Cancellations:</strong></p>
      <ul>
       <li>Cancellation policies are subject to variations contingent on factors such as the airline, fare type, and travel route. We strongly advise you to acquaint yourself with the precise cancellation conditions linked to your reservation.</li>
       <li>Cancellation procedures conducted via our website will adhere to the same outlined policies.</li>
      </ul>
      <p><strong>Refund Eligibility:</strong></p>
      <ul>
       <li>Refund eligibility hinges on the fare regulations stipulated by the airline and the specific ticket type purchased.</li>
       <li>While certain tickets may not be eligible for refunds, others could qualify for partial or complete reimbursements, depending on the fare parameters.</li>
      </ul>
      <p><strong>Refund Process:</strong></p>
      <ul>
       <li>Upon airline approval of a refund, {Application.ORG_DISPLAY_NAME} will process the refund in alignment with the stipulated airline refund policy.</li>
       <li>Refunds will be directed back to the original payment method used during the booking.</li>
      </ul>
      <p><strong>Processing Time:</strong></p>
      <p>The timeframe required to process refunds is variable and hinges on the airline's policies. In specific cases, refund processing might extend over several weeks.</p>
      <p><strong>Fee Deductions:</strong></p>
      <ul>
       <li>Airlines may impose specific cancellation fees, change fees, or administrative costs, as per their individual policies.</li>
       <li>{Application.ORG_DISPLAY_NAME} may also implement a service fee to manage cancellation and refund processes. This fee is designed to offset the administrative expenses associated with refund procedures.</li>
      </ul>
      <p><strong>Changes To Policy:</strong></p>
      <p>{Application.ORG_DISPLAY_NAME} retains the right to revise or modify this Cancellation & Refund Policy whenever necessary. Any alterations will take effect immediately upon being posted on our official website.</p>
      <p>By opting for {Application.ORG_DISPLAY_NAME} for your booking needs, you acknowledge that you have perused, comprehended, and consented to our Cancellation & Refund Policy. Should you require further elucidation or assistance, please do not hesitate to reach out to our dedicated customer support team.</p>
     </div>
    </div>
   </div>
   <Footer />
  </div>
  );
  };
  
  export default CancelRefund;