import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const About = () => {
  return (
  <div id="main-wrapper">
   <Helmet title={composePageTitle('About Us')} />
   <Header />
   <section className="page-header page-header-dark bg-secondary">
    <div className="container">
     <div className="row align-items-center">
      <div className="col-md-8">
       <h1>About Us</h1>
      </div>
      <div className="col-md-4">
       <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
        <li>
         <RouterLink to={PageUrl.HOME}>Home</RouterLink>
        </li>
        <li className="active">About Us</li>
       </ul>
      </div>
     </div>
    </div>
   </section>
   <div id="content">
    <div className="container">
     <div className="bg-white shadow-md rounded p-4">
      <p>Welcome to {Application.ORG_DISPLAY_NAME}! We take immense pride in being your premier destination for travel solutions that combine hassle-free experiences with wallet-friendly options. As a dedicated third-party consolidator, we excel in presenting you with unparalleled flight deals and astonishing offers across all major US airlines.</p>
      <p>Our fundamental mission revolves around turning your travel aspirations into concrete realities. Through top-tier booking services, we ensure a journey that's seamlessly smooth from takeoff to landing. At {Application.ORG_DISPLAY_NAME}, your satisfaction and the creation of unforgettable travel memories are our primary objectives. Our mission is clear and impactful: to shape your travel journey into a seamless, cost-effective, and genuinely enjoyable experience. With years of industry expertise, we've nurtured robust partnerships with major US airlines, granting us the privilege of offering exclusive deals that remain unmatched. Whether you're embarking on a solo adventure, planning a romantic escapade, or preparing for a family vacation, {Application.ORG_DISPLAY_NAME} is here to cater to your every need.</p>
      <p>Our defining feature is our unwavering commitment to customer satisfaction. Our team of dedicated travel experts is available around the clock, poised to guide you at every step of your journey. From finding the ideal flight to ensuring a seamless booking process, we consistently go above and beyond to make your travel experience not only extraordinary but truly exceptional.</p>
      <p>As you explore our user-friendly website, you'll unveil a world of boundless possibilities. Immerse yourself in a diverse spectrum of flight options, seize the opportunity of irresistible offers, and leave the intricacies to us, enabling you to concentrate on crafting cherished memories.</p>
      <div className="row mt-4 mb-2">
       <div className="col-md-4">
        <div className="featured-box style-1">
         <div className="featured-box-icon text-primary">
          {' '}
          <i className="far fa-thumbs-up"></i>
         </div>
         <h3>Best Deal Offer</h3>
         <p>
          Find our lowest deal to destinations worldwide, guaranteed
         </p>
        </div>
       </div>
       <div className="col-md-4">
        <div className="featured-box style-1">
         <div className="featured-box-icon text-primary">
          {' '}
          <i className="far fa-paper-plane"></i>{' '}
         </div>
         <h3>Easy Booking</h3>
         <p>
          Search, select and save - the fastest way to book your trip
         </p>
        </div>
       </div>
       <div className="col-md-4">
        <div className="featured-box style-1">
         <div className="featured-box-icon text-primary">
          {' '}
          <i className="far fa-eye"></i>{' '}
         </div>
         <h3>24/7 Customer Care</h3>
         <p>
          Get award-winning service and special deals by calling{' '}
          {Application.SUPPORT_PHONE}
         </p>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
   <Footer />
  </div>
  );
  };
  
  export default About;