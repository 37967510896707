import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const Privacy = () => {
  return (
  <div id="main-wrapper">
   <Helmet title={composePageTitle('Privacy Policy')} />
   <Header />
   <section className="page-header page-header-dark bg-secondary">
    <div className="container">
     <div className="row align-items-center">
      <div className="col-md-8">
       <h1>Privacy Policy</h1>
      </div>
      <div className="col-md-4">
       <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
        <li>
         <RouterLink to={PageUrl.HOME}>Home</RouterLink>
        </li>
        <li className="active">Privacy Policy</li>
       </ul>
      </div>
     </div>
    </div>
   </section>
   <div id="content">
    <div className="container">
     <div className="bg-white shadow-md rounded p-4">
      <div>
  
       <p>At {Application.ORG_DISPLAY_NAME}, safeguarding your privacy is our utmost priority. Our Privacy Policy underscores our unwavering dedication to protecting your personal information. Rest assured that any data you provide us with will be handled with the highest level of care and security. We only collect essential information necessary to facilitate your bookings and enhance your travel experience. Your trust in us is invaluable, and we are fully committed to maintaining the confidentiality and security of your data.</p>
       <p>Our Privacy Policy outlines how we gather, use, and safeguard your data when you interact with our website and services.</p>
       <p><strong>Information Collection:</strong></p>
       <p>When you explore our website or use our services, specific information is collected. This may include details such as your name, contact information, payment details, and travel preferences. We use this information to assist with bookings, personalise your journey, and provide important updates.</p>
       <p><strong>Data Usage:</strong></p>
       <p>We use your information to process bookings, offer customer support, and refine our services. Additionally, we may utilise your data for analytical purposes to improve user experiences and optimise our offerings.</p>
       <p><strong>Information Sharing:</strong></p>
       <p>In order to fulfil your bookings, we may share your data with third parties like airlines and payment processors. Rest assured, we do not sell or lease your information to external parties for marketing purposes.</p>
       <p><strong>Data Security:</strong></p>
       <p>We implement industry-standard security measures to protect your data against unauthorised access, alteration, or disclosure. However, complete security of online transmissions cannot be guaranteed, and absolute data security is beyond our control.</p>
       <p><strong>Third-Party Links:</strong></p>
       <p>Our website may contain links to third-party sites. We hold no responsibility for the privacy practices or content of such websites. We recommend reviewing their privacy policies before sharing any information.</p>
       <p>At {Application.ORG_DISPLAY_NAME}, we are firmly committed to preserving your privacy while enhancing your travel experience.</p>
      </div>
     </div>
    </div>
   </div>
   <Footer />
  </div>
  );
  };
  
  export default Privacy;