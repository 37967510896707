import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const Terms = () => {
  return (
  <div id="main-wrapper">
   <Helmet title={composePageTitle('Terms and Conditions')} />
   <Header />
   <section className="page-header page-header-dark bg-secondary">
    <div className="container">
     <div className="row align-items-center">
      <div className="col-md-8">
       <h1>Terms and Conditions</h1>
      </div>
      <div className="col-md-4">
       <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
        <li>
         <RouterLink to={PageUrl.HOME}>Home</RouterLink>
        </li>
        <li className="active">Terms and Conditions</li>
       </ul>
      </div>
     </div>
    </div>
   </section>
   <div id="content">
    <div className="container">
     <div className="bg-white shadow-md rounded p-4">
      <div>
       <p>Presenting {Application.ORG_DISPLAY_NAME}'s Terms & Conditions, thoughtfully curated to ensure absolute transparency throughout your travel odyssey. By selecting {Application.ORG_DISPLAY_NAME} as your travel companion, you enter into an agreement to uphold these terms, fostering mutual understanding between us. These terms vigilantly govern our service usage, providing crucial insights into your rights and obligations as a traveller &ndash; spanning from reservation processes to payment directives. Before proceeding with any bookings or transactions, we encourage you to meticulously peruse these terms.</p>
       <p><strong>Booking Reservations and Payments:</strong></p>
       <ul>
        <li>Each reservation made through {Application.ORG_DISPLAY_NAME} is contingent upon availability and the conditions specified by the respective airlines.</li>
        <li>Payment is obligatory at the point of booking. All listed prices are denominated in US dollars, unless explicitly stated otherwise.</li>
       </ul>
       <p><strong>Information Accuracy:</strong></p>
       <p>While we leave no stone unturned in offering accurate and current information, {Application.ORG_DISPLAY_NAME} cannot warrant the precision of flight schedules, fares, or any other particulars displayed on our platform. We strongly advise verifying information with the respective airlines.</p>
       <p><strong>Refunds and Cancellations:</strong></p>
       <ul>
        <li>Policies regarding cancellations and refunds are subject to the airline and fare category. It's your responsibility to meticulously comprehend and internalise these terms prior to finalising a booking.</li>
        <li>{Application.ORG_DISPLAY_NAME} relinquishes any liability for charges or penalties imposed by airlines in the event of changes or cancellations.</li>
       </ul>
       <p><strong>Liability Limitation:</strong></p>
       <ul>
        <li>{Application.ORG_DISPLAY_NAME} bears no liability for losses, damages, delays, or expenses arising from the actions or oversights of airlines, hotels, or other third-party service providers.</li>
        <li>In no event shall {Application.ORG_DISPLAY_NAME} be held responsible for any indirect, consequential, or punitive damages arising from your utilisation of our services.</li>
       </ul>
       <p><strong>Modifications to Terms and Conditions:</strong></p>
       <ul>
        <li>{Application.ORG_DISPLAY_NAME} maintains the right to revise or update these Terms & Conditions at its discretion. Regularly reviewing these terms lies solely within your purview.</li>
        <li>Through the utilisation of our services and website, you acknowledge having thoroughly examined, comprehended, and consented to these Terms & Conditions.</li>
       </ul>
      </div>
     </div>
    </div>
   </div>
   <Footer />
  </div>
  );
  };
  
  export default Terms;